#fourofour {
  background-color: $main-blue;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    color: #fff;
  }
}

.slide-appear,
.slide-enter {
  opacity: 0;
}

.slide-appear-active,
.slide-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.slide-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
}

#main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-gap: 5em;

  @media (max-width: $mobile-size) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    height: 100vh;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      height: 5rem;
      left: 0;
      right: 0;
      transform: skewY(-20deg);
      transform-origin: 0 100%;
      background-color: $main-blue;
      z-index: 4;
    }
  }

  .lefty {
    grid-column: 1 / 2;
    grid-row: 1;
    .sidebar-container {
      position: sticky;
      top: 0;
      left: 0;
      height: 100vh;
    }
    @media (max-width: $mobile-size) {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 5;
      background-color: $main-blue;

      &.close {
        width: 100%;
        height: 100%;
        transform-origin: 0px 50% 0px;
        transform: rotate(-20deg) translateY(-100%);
        transition: transform 1s ease-in-out;
      }

      &.open {
        width: 100%;
        height: 100%;
        top: 0em;
        transform-origin: 50% 50%;
        transform: rotate(0deg) translateY(0);
        transition: transform 1s ease-in-out;
      }
    }
  }

  .righty {
    z-index: $index-base;
    grid-column: 2 / 4;
    background-color: #fff;
    .home {
      display: flex;
      align-items: center;
      z-index: $index-base;
      height: 100vh;
    }
    @media screen and (max-width: $mobile-size) {
      padding: $large-space;
    }
  }
}

#burger {
  background: none;
  border: none;
  outline: none;
  display: none;
  cursor: pointer;

  @media (max-width: $mobile-size) {
    display: block;
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    width: 2rem;
    height: 2rem;
    color: #fff;
    font-size: 1rem;
    z-index: 7;
  }
}

#blogContent {
  margin-left: 5.5rem;
  max-width: 50rem;

  @media screen and (max-width: $mobile-size) {
    margin-left: 0;
  }
}