@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  33% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

@keyframes revealer {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@mixin dropdown() {
  .cv-dropdown {
    @extend .sans-serif;
    font-weight: 200;
    font-size: $preset-font;
    border: 1px solid $main-blue;
    outline: none;
    border-radius: 0px;
    background-color: $main-blue;
    box-shadow: none;
    padding-right: 1.3rem;

    @media (max-width: $mobile-size) {
      padding: 1rem;
      font-size: $preset-smaller;
    }

    .default.text {
      color: #fff;
    }
    .icon {
      float: right;
      color: #fff;
      &.cloud {
        animation: bounce 1s infinite;
        transition: animation 1s ease-in-out;
      }
    }

    .menu {
      border-radius: 0px;
      border-color: $main-blue;
      box-shadow: none;

      .menutem {
        a {
          display: block;
          @extend .sans-serif;
          color: $main-blue;
          padding: 1rem 1.25rem;

          @media (max-width: $mobile-size) {
            padding: 1rem;
            font-size: $preset-font * 0.8;
          }

          &:hover {
            color: #fff;
            background-color: $main-blue;
          }
        }
      }
    }
  }
}

@mixin box {
  .box-title {
    display: block;
    text-transform: uppercase;
    text-align: center;
    margin: 0.3rem 0;
    padding: 0;
    line-height: 1.1;

    .title {
      @extend .sans-serif;
      font-size: 1.55rem;
      font-weight: 600;
      margin-bottom: 0.2rem;

      @media (max-width: $mobile-size) {
        font-size: 1.2rem;
      }
    }
    .text {
      @extend .sans-serif;
      font-size: 1.06rem;
      color: $main-blue;
    }
  }
}

/*-------------
    Home
--------------*/

#home {
  position: relative;

  .home-details {
    z-index: $index-base;
    font-size: $preset-font;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container-top {
      display: grid;
      grid-template-columns: 12em 30em;
      grid-gap: $large-space;
      align-items: center;

      @media screen and (max-width: $small-screen) {
        grid-template-columns: 10em auto;
        grid-gap: 1em;
        font-size: $preset-smaller;
      }
    }
    .content {
      @media screen and (max-width: $small-screen) {
        font-size: $preset-smaller;
      }
    }
  }
}
