/*-------------
    <MainCV>
--------------*/

#mainportfolio {
  padding-right: 3rem;

  .field {
    @extend .sans-serif;
    font-weight: 400;
    padding: 0.5rem 1rem;

    strong {
      font-weight: 600;
    }
  }

  .description {
    .ui.label {
      border-radius: 0px;
    }
  }
}
