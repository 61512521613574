.u-larger-p {
  margin-bottom: $large-space;
  p {
    font-size: $standard-web-paragraph;
    strong {
      font-weight: 500;
    }
  }
}

.u-emphasize-text {
  color: $main-blue;
  font-weight: 500;
}

.u-home-text {
  font-size: 1em;
}

.u-image-responsive {
  max-width: 100%;
}

.u-margin-right {
  margin-right: $standard-space;
}

.u-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $standard-space;

  @media screen and (max-width: $mobile-size) {
    grid-template-columns: 1fr;
  }
}

.u-blog-index-header {
  margin-bottom: 0;
}

.u-block__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $standard-line-height;
  align-items: stretch;
}

.u-block__item {
  border: 0.1rem dashed $dark-grey;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: $standard-line-height;
  padding: $standard-line-height;
}

.u-blog-header-section {
  margin: 0;
}
