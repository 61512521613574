@use "sass:math";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,900,900i");

$grey: #eee;
$orange: #f6ae1b;
$dark: #414143;
$dark-grey: #868e96;
$main-blue: #44b0ee;
$light-grey: #f9f9f9; // code background

$preset-f: 1.3rem;
$standard-font: 1.05rem;
$standard-web-paragraph: 1.2rem;
$preset-font: 1.3rem;
$preset-smaller: math.div($preset-f, 1.55);
$medium-p: 1.4rem;
$large-p: 1.5rem;

$standard-line-height: 1.5rem;

$standard-space: $standard-line-height;
$medium-space: 2rem;
$large-space: 3rem;

// indeces
$index-base: 3; // On top of sidebar
$index-primary: 5;
$index-secondary: 10;
$index-third: 15;
$index-top: 100; // Modal

// media queries
$mobile-size: 44rem;
$small-screen: 54rem; // Table portrait mode, small and older deivces

.serif {
  font-family: "Source Serif Pro", serif;
}

.sans-serif {
  font-family: "Source Sans Pro", sans-serif;
}

.btn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

html,
body,
#root,
.layout {
  @media (min-width: $mobile-size) {
    height: 100%;
  }
  @media (max-width: $mobile-size) {
    height: auto;
  }
}

body {
  @extend .sans-serif;
  font-size: $standard-font;
}

h1 {
  @extend .sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom:$standard-space;
  hyphens: auto;
  @media (max-width: $mobile-size) {
    font-size: 2.2rem;
  }
  small {
    color: $dark-grey;
    font-weight: 300;
    font-size: $medium-space;
    @media (max-width: $mobile-size) {
      font-size: 1.8rem;
    }
  }
}
h2 {
  @extend .sans-serif;
  font-weight: 200;
  font-size: $medium-space;
  margin-bottom: 0.5rem;
  @media (max-width: $mobile-size) {
    font-size: 1.8rem;
  }
  &.ui.dividing.header {
    @extend .sans-serif;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    margin: $medium-space 0;
    padding: 0.5rem 0;
    font-weight: 200;
    @media (max-width: $mobile-size) {
      font-size:$standard-space;
    }
  }
}
h3 {
  @extend .sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  @media (max-width: $mobile-size) {
    font-size: 1.1rem;
  }
}
h4 {
  @extend .sans-serif;
  font-weight: 400;
  text-decoration: underline;

  small {
    font-weight: 600;
    color: $dark-grey;
  }
}

a {
  color: $orange;
  &:hover {
    color: $main-blue;
  }
}

p,
dl,
li, blockquote {
  @extend .sans-serif;
  font-size: $standard-font;
  font-weight: 200;
  line-height: $standard-line-height;
  hyphens: auto;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin:$standard-space 10px;
  padding: 0.5rem 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4rem;
  line-height: 0.1rem;
  margin-right: 0.25rem;
  vertical-align: -0.4rem;
}
blockquote p {
  display: inline;
}

code {
  background: #f9f9f9;
}

pre code {
  display: block;
  padding: 0.5rem;
}

.container {
  padding-top: $medium-space;
  padding-right: $medium-space;
}

.HtmlText {
  @extend .sans-serif;
  font-weight: 400;
  font-size: $standard-font;
  line-height: 1.5;

  .public-DraftStyleDefault-ul {
    list-style-type: square;
  }
}

strong {
  font-weight: 600;
}
section {
  margin: 1rem 0;
}

.btn-feeling-lucky {
  @extend .btn;

  background-color: $main-blue;
  color: #fff;
}
